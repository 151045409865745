:root{
  --primaryClr:#292929;
  --secondClr:#03a87c;
  --thirdClr:#0000008a;
}
body {
  margin: 0;
  padding: 0;
  font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-family: 'Playfair Display', serif;
  font-weight: 900;
}
p{
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a{
  color:#292929;
  color:var(--primaryClr);
  text-decoration: none;
}
.app-wrap{
  max-width: 1200px;
  margin:0 auto;
  padding: 0 20px;
  min-height: calc(100vh - 200px);
}
.App{
 position: relative;
 max-width: 100%;

}

.wrap-to-border{
  box-shadow: 0 4px 12px 0 rgba(0,0,0,.05)
}

  .navbar-wrap{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1200px;
    margin:0 auto;
    padding: 0 20px;
    color:#292929;
    color:var(--primaryClr);
    flex-wrap: wrap;
    /* border:1px solid pink; */
    
  }
  
  .right-side{
    display:grid;
    grid-template-columns: 30px 90px 110px;
    grid-column-gap: 10px;
    grid-template-rows: 1fr;
    font-size: 16px;
    margin:0 auto;

  }
  .btn-join{
    width: 110px;
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #03a87c;
    border: 1px solid var(--secondClr);
    color: #03a87c;
    color: var(--secondClr);
    background: transparent;
    border-radius: 5px;
    outline: none;
    padding:10px;
    font-size: 1rem;
    cursor: pointer;
   
  }
  .signin{
    text-align: center;
    align-self: center;
    padding-bottom: 3px;
    color:#03a87c;
    color:var(--secondClr);
    cursor: pointer;
  }
  .search-wrap{
    align-self: center;
    justify-self: right;
    height: 30px;
    position: relative;
    
  }
  .search-input{
    background:rgba(207, 207, 207, 0.164);
    width: 160px;
    height:100%;
    box-sizing: border-box;
    border: none;
    outline: none; 
    display: none;
  }
  .fa{
    width:30px;
    color:#0000008a;
    color:var(--thirdClr);
    cursor: pointer;
  }
  
  
  /* category nav */
  .category-wrap{
    height: 70px;
    box-sizing: border-box;
  }
  .sticky{
    position: fixed;
    top:0; left:0;
    background:white;
    height: 70px;
    z-index: 9999;
    right:0;
    margin:0 auto;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,.04);
  }
  .padding-sticky{
    padding-top:90px;
  }
  .category-list{
    padding:0;
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    list-style-type: none;
    text-transform: uppercase;
    align-items: center;
    font-size:0.95rem;
    color:#0000008a;
    color:var(--thirdClr);
    overflow-x: scroll;
    height: 100%;
    max-width: 1200px;
    margin:0 auto;
  }
  .category-list a{
    color:#0000008a;
    color:var(--thirdClr);
  }
  .item{
    padding: 0 10px;
  }
  .left-side{
    width: 100%;
    text-align:center;
  }
.loader-overlay{
  position: fixed;
  width:99vw;
  height: 100vh;
  z-index: 999;
  left:0;
  top:0;
  right:0;
  background:rgba(255, 255, 255);
  display:flex;
  justify-content: center;
  align-items: center;
}
.footer{
  height: 200px;
  width: 100%;
  background:#1F1F1F;
  color:rgba(255, 255, 255, 0.534);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  flex-direction: column;
  padding:0 20px;
  box-sizing: border-box;
  
}
.footer a{
  color:rgba(255, 255, 255, 0.808);
}
.site{
    height: 700px;
  }


@media(min-width:460px) {

  .right-side{
    grid-template-columns: 190px 90px 110px;
    margin:0;
  }
  .navbar-wrap{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .search-input{
    display: inline;
  }
}

@media(min-width:658px){
  .navbar-wrap{
    justify-content: space-between;
  }
  .left-side{
    text-align:left;
    width: 140px;
  }
  .category-list{
    color:#0000008a;
    color:var(--thirdClr);
    overflow-x: hidden;
    
  }
}
.signin-container{
    display:flex;
    justify-content: center;
    align-items: center;
    width:320px;
    height: 100%;
    flex-direction: column;
    margin:0 auto;
  
}
.login-form{
    display:flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    padding:20px;
    width:100%;
    
}

.btn{
    padding:15px;
    margin:10px 0;
    font-size:1.2rem;
    cursor: pointer;
    background:var(--primaryClr);
    color:white;
    border:0;
    border-radius:1px;
    outline: none;
    position:relative;
    z-index: 1;
}
.overlay{
    content: '';
    background: rgba(167, 167, 167, 0.158);
    width: 40%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    -webkit-transform:translateY(10%);
            transform:translateY(10%);  
    transition: all 1s ease-in-out;
    opacity:0;
    
    
}
.btn:hover .overlay{
    -webkit-transform:translateY(0%);
            transform:translateY(0%);  
    width: 100%;
    height: 100%;
    opacity:1;  
}
.login-form input{
    height: 40px;
    font-size:1.6rem;
    margin:6px 0;
}
.login-form label:first-child::before{
    content: '\F0E0   ';
	font-family: "FontAwesome";

}
.login-form label::before{
    content: '\F13E   ';
	font-family: "FontAwesome"; 
}
 .search-list{
   
    z-index: 900;
    position: absolute;
    top:50px;
    left:-30px;
    width:300px;
    padding: 0 50px;

}
.suggestions {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .suggestions li {
    background: rgba(240, 240, 240, 0.938);
    list-style: none;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    margin: 0;
    padding: 20px;
    transition: background 0.2s;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
  }
  
.content-wrap{
    display:grid;
    grid-row-gap: 30px; 
    grid-column-gap: 20px;
}
.page{
    margin:5% 0%;
    position: relative;
    font-size: 0.9rem;
    max-height: 450px;
    overflow: hidden;
}
.page-overlay{
    position: absolute;
    top:0;
    left:0;
    padding:3% 40% 3% 3%;
    overflow: hidden;

}

  .page p{
    line-height: 20px;
    font-size: 12px;
  }
  .page h1{
    font-size: 1.2rem;
    line-height: 28px;
    font-weight: 700;
  }
hr{
 border:0.5px solid rgba(0, 0, 0, 0.068);
 margin-top:0;
}
.date{
    font-size:.8rem;
    color: var(--thirdClr);
}
.card-wrap p{
    font-family: 'Segoe UI', 'Open Sans', sans-serif;
    color: var(--thirdClr);
    font-size:1rem;
}
.content-wrap img{
   width:100%;
   height: auto;
}
.ads-image{
    width:100%;
    height: auto;
}
.category-title{
    width: 80px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
}

@media (min-width:640px) {
    .content-wrap{
        grid-template-columns:1fr 1fr;
        grid-template-rows:auto ;
      
    }
    .card-wrap:nth-child(1){
        /* border:2px solid pink; */
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        font-size:1.2rem;
        display:grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
      
         
    }
    .card-wrap:nth-child(1) .card{
        /* align-self: center; */
    }
    .card-wrap:nth-child(1) img{
       min-height: auto;
       width:100%; 
    }
}
@media (min-width:960px) {

    .card-wrap:nth-child(1){

        grid-template-columns: 4fr 2fr;
       
         
    }
    .card-wrap:nth-child(1) .card{
        align-self: center;
    }
    .page p{
        line-height: 24px;
        font-size: 1rem;
      }
      .page h1{
        font-size: 2.65rem;
        line-height: 48px;
        font-weight: 700;
      }
    
}

@media (min-width:1286px) {
    .content-wrap{
        width: 100%;
        grid-template-columns: 320px 520px 320px;
        grid-template-rows:1fr 1fr 1fr;
        font-size:0.7rem;
        grid-gap:20px;
        
    }
    .content-wrap h2{
        margin: 0;
    }
    
    .card-wrap{
        display: grid; 
        grid-template-columns:200px auto;
        grid-template-rows: auto;   
        grid-row-gap:40px;
        /* border:1px dotted black; */
        
    }
    .pos{
        position: relative;
        width:180px;
        height: 150px;
        padding-right:20px;
    }
    .content-wrap img{
        width:180px;
        height: 120px;
        padding-right:20px;
        
        
    }
    .card-wrap:nth-child(1){
        /* border:2px solid pink; */
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 2;
        font-size:1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;   
        
    }
    .card-wrap:nth-child(1) img{
        width:360px;
        height: 240px;
        left:-40px;
        position: absolute;
        
    }
    .card-wrap:nth-child(1) .card{
        align-self:flex-start ;
    }
    
    
    .card-wrap:nth-child(2){
        /* border:2px solid rgb(141, 21, 41); */
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 3;
        grid-column-end: 4;
        font-size:1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;   
    }
    .card-wrap:nth-child(2) img{
        width:360px;
        height: 240px;
        position: absolute;
    }
}













.main-category-wrap{
    display:grid;
    grid-gap:30px;
    margin: 5% 0;
    
}
.category-image{
    width:100%;
    height: auto;
}
.main-category-wrap h2{
    margin:0;
}
.main-category-wrap p {
    line-height: 26px;
}

@media(min-width:750px) {

    .main-category-wrap{
        display:grid;
        grid-template-columns: 5fr 3fr;
        grid-template-areas: auto;
        
    
    }
    .category-image{
        grid-column-start: 2;
        grid-column-end: 3;
    }
    .category-text-wrap{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        align-self: center;
    }

}




.page-container{
    /* border:1px solid green; */
    padding-top: 20px;
    display:grid;
    grid-template-rows:auto;
    grid-gap:0;
    
}
.page-container h1{
    margin: 0;
    font-size: 3.5rem;
    line-height: 64px;
    word-wrap: break-word;
}

.figure{
    width:104%;
    -webkit-transform:translateX(-2%);
            transform:translateX(-2%);
    height: auto;
    grid-row-start: 1;
    grid-row-end: 2;
  
}
.page-container h3 a{
    color:var(--secondClr);
    text-transform: uppercase;
}
.page-container h4{
    color:var(--thirdClr);
    font-weight: 300;
    font-size:1.45rem;
    line-height: 28px;
    
}

.text-page p{
    font-size:1.35rem;
    line-height: 33px;
    margin-bottom: 5%;
    font-weight: 300;    
}

.side-bar{
    justify-self:center;
    
    
}
.fa{
    color:var(--primaryClr);
}

em, u{
    font-weight: 300;
    padding:10px
}
em::after{
    content: ' "';
}
em::before{
    content: '" ';
}
.text-page img{
    max-height: 100%;
    max-width: 100%;
}

@media(min-width:940px) {

    .page-container{
        
        grid-template-columns: repeat(10, 1fr);
        grid-row: auto;
        padding-top: 0px;
        align-items: center;
        grid-column-gap: 30px;
        grid-row-gap: 60px;
    }
    .figure{
        width:103%;
        height: auto;
        grid-column-start: 5;
        grid-column-end:11;
        grid-row-start: 1;
        grid-row-end: 2;
        -webkit-transform:translateX(0%);
                transform:translateX(0%);
    }
  
    .page-wrap{
        padding: 0 10px 0 0;
        grid-column-start: 1;
        grid-column-end:5;
    }
    .text-page{
        /* border:1px solid yellow; */
        grid-column-start: 3;
        grid-column-end:11;
        grid-row-start: 2;
        grid-row-end: 3;
        padding: 0 20px;
    }
    
    .side-bar{
        grid-column-start: 1;
        grid-column-end:3;
        align-self: flex-start;
        padding-top:50%;
        /* border:1px solid green; */
    }
    .vote-container{
        position: relative;
    
    }
    .vote{
        font-size: 0.7rem;
        position: absolute;
        top:-12px;
        left:29px;
        

    }

}
@media(min-width:1340px) {

    .figure{
        width:110%;
    }

}

@media(min-width:1840px) {

    .figure{
        width:130%;
    }

}
