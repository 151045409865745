.signin-container{
    display:flex;
    justify-content: center;
    align-items: center;
    width:320px;
    height: 100%;
    flex-direction: column;
    margin:0 auto;
  
}
.login-form{
    display:flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    padding:20px;
    width:100%;
    
}

.btn{
    padding:15px;
    margin:10px 0;
    font-size:1.2rem;
    cursor: pointer;
    background:var(--primaryClr);
    color:white;
    border:0;
    border-radius:1px;
    outline: none;
    position:relative;
    z-index: 1;
}
.overlay{
    content: '';
    background: rgba(167, 167, 167, 0.158);
    width: 40%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    transform:translateY(10%);  
    transition: all 1s ease-in-out;
    opacity:0;
    
    
}
.btn:hover .overlay{
    transform:translateY(0%);  
    width: 100%;
    height: 100%;
    opacity:1;  
}
.login-form input{
    height: 40px;
    font-size:1.6rem;
    margin:6px 0;
}
.login-form label:first-child::before{
    content: '\f0e0 ';
	font-family: "FontAwesome";

}
.login-form label::before{
    content: '\f13e ';
	font-family: "FontAwesome"; 
}