.page-container{
    /* border:1px solid green; */
    padding-top: 20px;
    display:grid;
    grid-template-rows:auto;
    grid-gap:0;
    
}
.page-container h1{
    margin: 0;
    font-size: 3.5rem;
    line-height: 64px;
    word-wrap: break-word;
}

.figure{
    width:104%;
    transform:translateX(-2%);
    height: auto;
    grid-row-start: 1;
    grid-row-end: 2;
  
}
.page-container h3 a{
    color:var(--secondClr);
    text-transform: uppercase;
}
.page-container h4{
    color:var(--thirdClr);
    font-weight: 300;
    font-size:1.45rem;
    line-height: 28px;
    
}

.text-page p{
    font-size:1.35rem;
    line-height: 33px;
    margin-bottom: 5%;
    font-weight: 300;    
}

.side-bar{
    justify-self:center;
    
    
}
.fa{
    color:var(--primaryClr);
}

em, u{
    font-weight: 300;
    padding:10px
}
em::after{
    content: ' "';
}
em::before{
    content: '" ';
}
.text-page img{
    max-height: 100%;
    max-width: 100%;
}

@media(min-width:940px) {

    .page-container{
        
        grid-template-columns: repeat(10, 1fr);
        grid-row: auto;
        padding-top: 0px;
        align-items: center;
        grid-column-gap: 30px;
        grid-row-gap: 60px;
    }
    .figure{
        width:103%;
        height: auto;
        grid-column-start: 5;
        grid-column-end:11;
        grid-row-start: 1;
        grid-row-end: 2;
        transform:translateX(0%);
    }
  
    .page-wrap{
        padding: 0 10px 0 0;
        grid-column-start: 1;
        grid-column-end:5;
    }
    .text-page{
        /* border:1px solid yellow; */
        grid-column-start: 3;
        grid-column-end:11;
        grid-row-start: 2;
        grid-row-end: 3;
        padding: 0 20px;
    }
    
    .side-bar{
        grid-column-start: 1;
        grid-column-end:3;
        align-self: flex-start;
        padding-top:50%;
        /* border:1px solid green; */
    }
    .vote-container{
        position: relative;
    
    }
    .vote{
        font-size: 0.7rem;
        position: absolute;
        top:-12px;
        left:29px;
        

    }

}
@media(min-width:1340px) {

    .figure{
        width:110%;
    }

}

@media(min-width:1840px) {

    .figure{
        width:130%;
    }

}